import state from './modulePackagesState'
import mutations from './modulePackagesMutations'
import actions from './modulePackagesActions'
import getters from './modulePackagesGetters'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}