import axios from "@/axios.js"

export default {
    fetchProducts({commit},page){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/products/index?page=${page}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    updatePackage({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/packages/update/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchPackages({commit},page){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/packages/index?page=${page}`)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    createNewPackage({commit},payload){
        return new Promise((resolve,reject)=>{
      
            axios.post('api/dashboard/packages/store',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    deletePackage({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/packages/delete/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
}